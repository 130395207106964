
import { Options, Vue } from 'vue-class-component';
import { Requester } from '../requester';
import { ErrorInfo } from '../network';
import ErrorDisplay from '../components/ErrorDisplay.vue';

@Options( {
	components: { ErrorDisplay },
	data () {
		return {
			loginResult: '',
			error: new ErrorInfo(),
			login: '',
			password: ''
		};
	},
	methods: {
		async sendLoginForm () {
			const result = await Requester.post<any>( 'login', {
				loginData: this.login,
				password: this.password
			} );

			if ( !( 'data' in result ) ) {
				// In case of failed to fetch at all
				this.error = result;
			} else {
				if ( result.data.status === 'ok' ) {
					// Refresh to get new content for logged in user
					setTimeout( () => {
						location.assign( 'https://play.rpgroot.com' );
					}, 1000 );
				} else {
					this.error = new ErrorInfo( result.data.errorMessage, result.data.errorCode, '...' );
				}
			}
		}
	}
} )
export default class Login extends Vue {}
