
import { Options, Vue } from 'vue-class-component';

@Options( {
	data () {
		return {
			buildTime: process.env.VUE_APP_BUILD_TIME || new Date().toISOString(),
			commitHash: process.env.VUE_APP_GIT_HASH,
			tag: process.env.VUE_APP_GIT_TAG === 'null' ? '' : ` / ${process.env.VUE_APP_GIT_TAG}`
		};
	}
} )

export default class Footer extends Vue {}
