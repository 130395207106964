
export class RpgNetworkResponse<T> {
	msg : string;
	data : T;

	private constructor ( msg : string, data : T ) {
		this.msg = msg;
		this.data = data;
	}

	/**
	 * Transform generic network response.body into acctual data type.
	 *
	 * It could be a certain data type or `ErrorInfo`.
	 *
	 * @param param0 req.body
	 * @returns value in a given type
	 */
	static from<T> ( { msg, data } : {msg : string, data : T | ErrorInfo} ) : RpgNetworkResponse<T | ErrorInfo> {
		console.log( 'Network response received with data', { data } );

		return new RpgNetworkResponse( msg, data );
	}
}
export class ErrorInfo {
	msg : string;
	reason : string;
	action : string;

	public constructor ( msg = '', reason = '', action = '' ) {
		this.msg = msg;
		this.reason = reason;
		this.action = action;
	}

	public toJSON () : Record<string, string> {
		return {
			msg: this.msg,
			reason: this.reason,
			action: this.action
		};
	}
}
