
import { Options, Vue } from 'vue-class-component';
import { Requester } from '../requester';

@Options( {
	data () {
		return {
			worldsList: [] as any[],
			error: ''
		};
	},
	created () {
		this.getGamesList();
	},
	methods: {
		async getGamesList () {
			const result = await Requester.get<any>( 'agg/games-list' );
			if ( 'data' in result ) {
			// if (result.data) {
				this.worldsList = result.data as Array<any>;
			} else {
				console.error( result );
			}
		}
	}
} )
export default class Worlds extends Vue {}
// TODO use the same end point as im post login but with different displaying
