
import { Options, Vue } from 'vue-class-component';
import { ErrorInfo } from '../network';

@Options( {
	props: {
		error: ErrorInfo
	},
	methods: {
		close () {
			this.error.msg = '';
		}
	}
} )

export default class ErrorDisplay extends Vue {
	// props : [ 'error' ]
}
