import { Config } from './cfg';
import { RpgNetworkResponse, ErrorInfo } from './network';

class Requester {
	private cfg ! : Config;
	private replaceSubdomain ! : boolean;

	public initialize ( config : Config ) : void {
		this.cfg = config;
		this.replaceSubdomain = config.shouldReplaceSubdomain();
	}

	public async get<T> ( url : string ) : Promise< RpgNetworkResponse< T > | ErrorInfo > {
		const replacePart = this.replaceSubdomain ? '?replace=true' : '';
		url = this.cfg.getHost() + '/v1/' + url + replacePart;
		// console.log( 'requester::get()', url );

		try {
			const data = await fetch( url, {
				method: 'GET'
			} );
			console.log( data );
			const jsonData = await data.json();

			return jsonData;
		} catch ( error : any ) {
			console.log( 'requester::get() -> error', error );

			const res : ErrorInfo = new ErrorInfo(
				error.message,
				'System failed to receive data.',
				'Contact with administration and deliver this entire message.'
			);

			return res;
		}
	}

	public async post<T> ( url : string, data : Record<string, unknown> ) : Promise< RpgNetworkResponse< T > | ErrorInfo > {
		if ( !this.cfg ) {
			const err = 'Requester is not initialized!';
			const res = new ErrorInfo(
				err,
				'System failed to receive data.',
				'Contact with administration and deliver this entire message.'
			);
			return res;
		}
		const replacePart = this.replaceSubdomain ? '?replace=true' : '';
		url = this.cfg.getHost() + '/v1/' + url + replacePart;
		console.log( 'requester::post()', url );
		try {
			const response = await fetch( url, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify( data )
			} );

			const results = await response.json() as RpgNetworkResponse<T>;

			return results;
		} catch ( e : any ) {
			const res = new ErrorInfo(
				e.message,
				'Technical error occured.',
				'Contact with administration and deliver this entire message.'
			);

			return res;
		}
	}
}

const r = new Requester();
export { r as Requester };
