let cfg;

class Config {
	private host = '';
	private replaceSubdomain : boolean;

	constructor ( host : string, replaceSubdomain = false ) {
		this.host = host;
		this.replaceSubdomain = replaceSubdomain;
	}

	public getHost () : string {
		return this.host;
	}

	public shouldReplaceSubdomain () : boolean {
		return this.replaceSubdomain;
	}
}

export { cfg, Config };
