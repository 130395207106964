
import { Options, Vue } from 'vue-class-component';

@Options( {
	components: {},
	mounted () {
		( function ( wind, doc, scriptElem, url, ns, r ) {
			( wind as any ).MailerLiteObject = ns;

			function f () {
				const c = { a: arguments, q: [] };
				const r = this.push( c );

				return typeof r !== 'number' ? r : f.bind( c.q );
			}

			f.q = f.q || [];
			wind[ns] = wind[ns] || f.bind( f.q );
			wind[ns].q = wind[ns].q || f.q;
			r = doc.createElement( scriptElem );

			const _ = doc.getElementsByTagName( scriptElem )[0];
			r.async = 1;
			r.src = url + '?v' + ( ~~( new Date().getTime() / 1000000 ) );
			_.parentNode.insertBefore( r, _ );
		} )( window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml' );

		// eslint-disable-next-line camelcase
		const ml_account = ( window as any ).ml( 'accounts', '3495554', 'i0n6w6v8d9', 'load' );
	}
} )

export default class Newsletter extends Vue { }

