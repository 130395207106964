import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd02e1a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "ml-form-embed",
  "data-account": "3495554:i0n6w6v8d9",
  "data-form": "5937296:c3p4u3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}