import { createApp } from 'vue';
import VueGtag from 'vue-gtag';

import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import { Requester } from './requester';
import { Config } from './cfg';

let cfg : Config;
if ( process.env.NODE_ENV === 'production' ) {
	cfg = new Config( 'https://rpgroot.com' );
} else {
	cfg = new Config( 'http://localhost:3000' );
}
Requester.initialize( cfg );

createApp( App )
	.use( router )
	.use(
		VueGtag,
		{
			config: { id: 'G-SY6S58FT47' }
		},
		router
	)
	.mount( '#app' );
