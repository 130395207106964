import { createRouter, createWebHashHistory } from 'vue-router';
import Index from '../views/Index.vue';
import Worlds from '../views/Worlds.vue';
import Games from '../views/Games.vue';
import Login from '../views/Login.vue';
import Glossary from '../views/Glossary.vue';
import Api from '../views/Api.vue';
import Newsletter from '../views/Newsletter.vue';

import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import Terms from '../views/Terms.vue';

const routes = [
	{
		path: '',
		name: 'Home',
		component: Index
	},
	{
		path: '/terms-of-service',
		name: 'TermsOfService',
		component: Terms
	},
	{
		path: '/privacy-policy',
		name: 'PrivacyPolicy',
		component: PrivacyPolicy
	},
	{
		path: '/newsletter',
		name: 'Newsletter',
		component: Newsletter
	},
	{
		path: '/glossary',
		name: 'Glossary',
		component: Glossary
	},
	{
		path: '/api',
		name: 'Api',
		component: Api
	},
	{
		path: '/games',
		name: 'Games',
		component: Games
	},
	{
		path: '/worlds',
		name: 'Worlds',
		component: Worlds
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	}
];

const router = createRouter( {
	history: createWebHashHistory( process.env.BASE_URL ),
	routes
} );

export default router;
